import ChevRight from 'images/svg/chev-right.svg'
import React, { useEffect, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'


// Used code from https://codepen.io/emgoto/pen/jOyORjZ

const Nav = styled.nav`
  ${({ theme }): CSSProp => css`
    border: 1px solid ${theme.colours.lightTint3};
    padding: 2rem 4rem 0rem 4rem;
    margin: 0 0 4rem 0;

    .desktop-toc & {
      display: none;
    }

    .tablet-toc & {
      display: none;
    }

    @media (min-width: 1201px) {
      .desktop-toc & {
        display: block;
      }
    }

    @media (max-width: 1200px) {
      .tablet-toc & {
        display: block;
      }
    }
  `}
`

const Heading = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeBodyDefault};
  `}
`

const HeadingsList = styled.ul`
  ${({ theme }): CSSProp => css`
    padding-inline-start: 0;
    list-style-type: none;
  `}
`

const HeadingsListIndent = styled.ul`
  ${({ theme }): CSSProp => css`
    padding-inline-start: 2rem;
    list-style-type: none;
  `}
`

const ListIcon = styled(ChevRight)`
  ${({ theme }): CSSProp => css`
    position: absolute;
    left: -15px;
    top: 3px;
  `}
`

const HeadingLink = styled.a`
  ${({ theme }): CSSProp => css`
    position: relative;
    font-size: ${theme.font.fontSizeBodyMobile};
    text-decoration: none;
  `}
`

const scrollTo = (element: HTMLElement): void => {
  const yOffset = -80
  const elementtoScrollTo = document.querySelector(`#${element.id}`)
  const y = elementtoScrollTo.getBoundingClientRect().top + window.pageYOffset + yOffset
  window.scrollTo({ top: y, behavior: 'smooth' })
}

const Headings = ({ headings }): JSX.Element => (
  <HeadingsList>
    {headings.map((heading) => (
      <li key={heading.id}>
        <HeadingLink
          href={`#${heading.id}`}
          onClick={(e): void => {
            e.preventDefault()
            scrollTo(heading)
          }}
        >
          <ListIcon />
          {heading.title}
        </HeadingLink>
        {heading.items.length > 0 && (
          <HeadingsListIndent>
            {heading.items.map((child) => (
              <li key={child.id} >
                <HeadingLink
                  href={`#${child.id}`}
                  onClick={(e): void => {
                    e.preventDefault()
                    scrollTo(child)
                  }}
                >
                  <ListIcon />
                  {child.title}
                </HeadingLink>
              </li>
            ))}
          </HeadingsListIndent>
        )}
      </li>
    ))}
  </HeadingsList>
)

const getNestedHeadings = (headingElements): Element[] => {
  const nestedHeadings = []

  headingElements.forEach((heading) => {
    const { innerText: title, id } = heading

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] })
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title
      })
    }
  })

  return nestedHeadings
}

const slugify = (text): string => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
    .replace(/--+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')            // Trim - from end of text
}

const addIdsToHeadings = (headings): [] => {
  return headings.map((heading)=> {
    heading.id = `heading-${slugify(heading.textContent)}`
    return heading
  })
}

const useHeadingsData = (): { nestedHeadings: Element[] } => {
  const [nestedHeadings, setNestedHeadings] = useState([])
  
  useEffect(() => {
    const contentSection = document.querySelector('div.row > div.content')

    if (contentSection) {
      const headingElements = Array.from(
        contentSection.querySelectorAll("h2, h3")
      )
  
      const headingElementsWithIds = addIdsToHeadings(headingElements)
  
      const newNestedHeadings = getNestedHeadings(headingElementsWithIds)
      setNestedHeadings(newNestedHeadings)
    }
  }, [])

  return { nestedHeadings }
}

const TableOfContents = (): JSX.Element => {
  const { nestedHeadings } = useHeadingsData()

  return (
    <>
      {nestedHeadings.length > 1 && <Nav aria-label="Table of contents">
          <Heading>Jump to</Heading>
          <Headings headings={nestedHeadings} />
      </Nav>}
    </>
  )
}

export default TableOfContents
