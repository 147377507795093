import Icon from 'components/atoms/icon'
import { useResourceTextData } from 'hooks/useResourceTextData'
import LinkIcon from 'images/svg/link.svg'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledOpenStatus = styled.span`
  ${({ theme }): CSSProp => css`
    .open,
    .closed {
      text-transform: uppercase;
      color: ${theme.colours.tertiary};
      font-weight: bold;
      letter-spacing: 1px;
    }
    .closed {
      color: #fe4444;
    }
  `}
`

const OpenStatus = ({ status, getOpeningHours }): JSX.Element => {
  const unknownText = useResourceTextData('global.unknown', 'Open now?')
  const openText = useResourceTextData('global.open', 'Open now')
  const closedText = useResourceTextData('global.closed', 'Closed')
  const tempClosedText = useResourceTextData(
    'global.tempClosed',
    'Temporarily closed'
  )
  const permClosedText = useResourceTextData(
    'global.permClosed',
    'Permanently closed'
  )
  return (
    <StyledOpenStatus>
      {status && (
        <>
          {status === 'unknown' && <div className="link" onClick={getOpeningHours} style={{ textDecoration: 'underline', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <Icon className="link-icon" size='medium' style={{ width: '1.2rem' }}>
              <LinkIcon />
            </Icon>{unknownText}</div>}
          {status === 'open' && <span className="open">{openText}</span>}
          {status === 'closed' && <span className="closed">{closedText}</span>}
          {status === 'CLOSED_TEMPORARILY' && (
            <span className="closed">{tempClosedText}</span>
          )}
          {status === 'CLOSED_PERMANENTLY' && (
            <span className="closed">{permClosedText}</span>
          )}
        </>
      )}
    </StyledOpenStatus>
  )
}

export default OpenStatus
