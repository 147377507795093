import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby-plugin-intl'
import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'

import { getLocalNodes } from '../../helpers/locale'
import { useResourceTextData } from '../../hooks/useResourceTextData'
import Close from '../../images/svg/close.svg'
import Mag from '../../images/svg/mag.svg'
import Link from '../atoms/link'
import Container from '../container'

const StyledCommunitySearchBar = styled.div<{ expanded: boolean }>`
  ${({ theme, expanded }): CSSProp => css`
    position: relative;
    border: solid ${theme.colours.primary};
    border-width: 1px 0;
    margin-bottom: 6rem;
    border-bottom-color: ${expanded ? 'transparent' : theme.colours.primary};

    @media only screen and ${theme.breakpoints.toMediumScreen} {
      border: none;
      margin-bottom: 4rem;
    }

    .bar {
      display: flex;
      align-items: center;
      height: 8rem;
      border-bottom: solid 1px
        ${expanded ? theme.colours.primary : 'transparent'};
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        height: 7rem;
      }
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        border-bottom-color: ${theme.colours.lightTint3};
        height: 5rem;
      }
    }
    svg {
      width: 2.2rem;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        width: 1.6rem;
      }
    }
    .search {
      flex: 1;
      margin: 0 2rem;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        margin: 1.5rem;
      }
    }
    input {
      width: 100%;
      font-size: 2.2rem;
      color: ${theme.colours.primary};
      border: none;
      background: none;
      font-weight: ${theme.font.fontWeightLight};
      padding: 2.4rem 0;
      outline: none;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        font-size: 1.8rem;
        padding: 1.4rem 0;
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
    button {
      cursor: pointer;
      margin-left: auto;
      background: none;
      border: none;
      svg {
        width: 1.6rem;
        @media only screen and ${theme.breakpoints.toMediumScreen} {
          width: 1.2rem;
        }
      }
    }
    .tags {
      padding: 6rem 0 10rem;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1;
      background: ${theme.colours.secondary};
      box-shadow: 0 8px 10px 0 rgba(43, 25, 22, 0.12);
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        padding: 4rem 0;
      }

      h3 {
        font-size: 11px;
        font-weight: ${theme.font.fontWeightBold};
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 2rem;
      }
      ul {
        margin: 0;
        padding: 0;
        max-width: 49rem;
      }
      li {
        display: inline-block;
        margin: 0 2rem 1rem 0;
        font-size: 12px;
      }
      .active {
        font-weight: ${theme.font.fontWeightBold};
      }
    }
    .tag-selection {
      font-size: 14px;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 14px;
      }

      font-weight: ${theme.font.fontWeightLight};
      margin-right: 1.5rem;
    }
    .tag-selected {
      font-size: 12px;
      font-weight: normal;
      position: relative;
      top: 1px;
    }
  `}
`

const CommunitySearchBar = ({
  close,
  query = '',
  tag,
}: {
  close?: any
  query?: string
  tag?: string
}): JSX.Element => {
  const [expanded, setExpanded] = useState(close ? true : false)
  const [searchText, setSearchText] = useState(query)

  const inputRef = useRef(null)
  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef.current])

  // handle click outside events
  const searchBarRef = useRef(null)
  const onClickOutside = (e): void => {
    if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
      setExpanded(false)
      close && close()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)
    return (): void => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  const communityTextBrowseTags = useResourceTextData(
    'community.browseTags',
    'Browse tags'
  )
  const communityTextSearchBoxPlaceholder = useResourceTextData(
    'community.searchPlaceholder',
    'Search'
  )
  const communityTextSearchBoxTagSelection = useResourceTextData(
    'community.searchTagSelection',
    'Tag selection'
  )

  const { allContentfulArticleTag } = useStaticQuery(
    graphql`
      query communityTags {
        allContentfulArticleTag(sort: { fields: title }) {
          nodes {
            id
            title
            slug
            node_locale
          }
        }
      }
    `
  )
  const allContentfulArticleTagLocal = getLocalNodes(
    allContentfulArticleTag.nodes
  )

  return (
    <StyledCommunitySearchBar ref={searchBarRef} expanded={expanded}>
      <Container>
        <div className="bar" onClick={(): void => setExpanded(true)}>
          {tag ? (
            <>
              <span className="tag-selection">
                {communityTextSearchBoxTagSelection}:
              </span>
              <span className="tag-selected">{tag}</span>
            </>
          ) : (
            <>
              <Mag />
              <form
                className="search"
                onSubmit={(e): void => {
                  e.preventDefault()
                  setExpanded(false)
                  navigate(`/community/search-results/?query=${searchText}`)
                }}
              >
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={communityTextSearchBoxPlaceholder}
                  value={searchText}
                  onChange={(e): void => setSearchText(e.target.value)}
                />
              </form>
            </>
          )}
          <button
            type="button"
            onClick={(e): void => {
              e.stopPropagation()
              setExpanded(false)
              if (close) {
                close()
              } else {
                setSearchText('')
                navigate(`/community/search-results`)
              }
            }}
          >
            <Close />
          </button>
        </div>
      </Container>

      {expanded && (
        <div className="tags">
          <Container>
            <h3>{communityTextBrowseTags}</h3>
            <ul>
              {allContentfulArticleTagLocal?.map(({ id, title, slug }) => (
                <li key={id}>
                  <Link to={`/community/tag/${slug}/`}>{title}</Link>
                </li>
              ))}
            </ul>
          </Container>
        </div>
      )}
    </StyledCommunitySearchBar>
  )
}

export default CommunitySearchBar
