import { isJapanese } from 'helpers/locale'
import styled, { css, CSSProp } from 'styled-components'

import {
  H1Style,
  H2Style,
  H3Style,
  H4Style,
  H5Style,
  H6Style,
} from '../../styles/base'

const ArticleBody = styled.div<{ hasImage?: boolean; type?: string }>`
  ${({ theme, hasImage, type }): CSSProp => css`
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: ${theme.colours.light};
    font-size: ${theme.font.fontSizeLarge};
    font-weight: ${theme.font.fontWeightLight};
    ${type === 'standalone' &&
      css`
        margin: 2rem 0;
        background: none !important;
      `}
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      background-color: ${
        type === 'cafe' ? theme.colours.light : theme.colours.secondary
      };
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 0;
      font-size: ${theme.font.fontSizeBodyMobile};
      padding: ${hasImage ? '6rem' : '3rem'} 0;
      min-height: 50rem;
      ${type === 'standalone' &&
        css`
          min-height: 0;
          margin: 4rem 0;
        `}
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      font-size: ${theme.font.fontSizeBodyDefault};
      margin: 0;
      padding: ${hasImage ? '7rem' : '3rem'} 0;
      ${type === 'standalone' &&
        css`
          margin: 7rem 0;
        `}

    }

    h1 {
      ${H1Style}
    }
    h2 {
      ${H2Style}
    }
    h3 {
      ${H3Style}
    }
    h4 {
      ${H4Style}
    }
    h5 {
      ${H5Style}
    }
    h6 {
      ${H6Style}
    }

    blockquote {
      font-weight: ${theme.font.fontWeightLight};
      font-size: 2.6rem;
      line-height: 1.4;
      padding: 0;
      margin: 4rem 0;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 3.2rem;
        margin: 5rem 0;
      }
    }
    h1 + p {
      margin-top: 0;
    }
    .lead {
      font-size: 1.8rem;
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      margin-top: 0;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 2rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 2.6rem;
      }
    }
    .content,
    .meta {
      margin: 0 auto;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        max-width: 62rem;
      }
    }
    .article-body blockquote p {
      text-align: center;
      font-size: 2rem;
      font-family: ${ theme.font.fontFamilyAlt };
      font-weight: ${ theme.font.fontWeightBold };
    }
    .row {
      position: relative;
    }
    .meta {
      ${type === 'cafe' &&
        css`
          margin-bottom: 4rem;
          @media only screen and ${theme.breakpoints.fromNormalScreen} {
            margin-bottom: 6rem;
          }
        `}

      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        max-width: 22rem;
        position: absolute;
        top: 0;
        z-index: 1;
      }

      @media only screen and ${theme.breakpoints.toNormalScreen} {
        /* display: none; */
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin-bottom: 3rem;
      }

      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        max-width: 34rem;
      }
    }
    .title {
      font-size: 3.8rem;
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      }
    .tablet-title {
      margin-bottom: 3rem;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        display: none;
      }
      h1 {
        margin-bottom: 1rem;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          font-size: 2.8rem;
        }
      }
      .sub-title {
        color: ${theme.colours.tertiary};
      }
    }
    ${type === 'cafe' &&
      css`
        .meta .tablet-title {
          @media only screen and ${theme.breakpoints.fromLargeScreen} {
            display: none;
          }
        }
        .content .tablet-title {
          @media only screen and ${theme.breakpoints.toLargeScreen} {
            display: none;
          }
        }
      `}

    .share {
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        display: none;
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin-left: 3rem;
        position: absolute;
        left: 0;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-left: 10rem;
      }
    }
    .page-meta {
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin-bottom: 3rem;
      }

      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        max-width: 22rem;
      }

      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        max-width: 34rem;
      }
    }
  `}
`

export default ArticleBody
