import { gql, useLazyQuery } from '@apollo/client'
import Animate from 'components/atoms/animate'
import Card from 'components/molecules/card'
import { getCurrentLang } from 'helpers/locale'
import { useResourceTextData } from 'hooks/useResourceTextData'
import React, { useEffect, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulArticle } from 'types/graphql-types'

export const ArticlesGrid = styled.div`
  ${({ theme }): CSSProp => css`
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 8rem;
    margin-bottom: ${theme.padding.screenDefault};
    grid-template-columns: repeat(2, 1fr);

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      grid-template-columns: repeat(6, 1fr);
    }

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      grid-template-columns: repeat(12, 1fr);
    }
  `}
`

export const ArticleGridItem = styled.div<{ uniform?: boolean }>`
  ${({ theme, uniform }): CSSProp => css`
    &:nth-child(7n + 7) {
      grid-column: span ${uniform ? 1 : 2};
    }

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      grid-column: span 3;

      &:nth-child(7n + 5),
      &:nth-child(7n + 6),
      &:nth-child(7n + 7) {
        grid-column: span ${uniform ? 3 : 2};
      }
    }

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      &:nth-child(7n + 1),
      &:nth-child(7n + 2),
      &:nth-child(7n + 3),
      &:nth-child(7n + 4) {
        grid-column: span 3;
      }

      &:nth-child(7n + 5),
      &:nth-child(7n + 6),
      &:nth-child(7n + 7) {
        grid-column: span ${uniform ? 3 : 4};
      }
    }
  `}
`

export const LoadMore = styled.div`
  ${({ theme }): CSSProp => css`
    margin-bottom: 6rem;
    text-decoration: underline;
    cursor: pointer;
    text-transform: uppercase;
    font-size: ${theme.font.fontSizeSmall};
    text-align: center;
  `}
`

const ArticlesList = ({
  articles,
  more,
  categorySlug,
  skip = 7,
  uniform,
  tags,
  exclude = [],
}: {
  articles: ContentfulArticle[]
  more?: boolean
  categorySlug?: string
  skip?: number
  uniform?: boolean
  exclude?: string[]
}): JSX.Element => {
  const excludeString = exclude
    .map(slug => {
      return '"' + slug + '"'
    })
    .join(',')

  const categoryClause = `${
    categorySlug ? `category: {slug: "${categorySlug}"}` : ``
  }`

  const excludeClause = `${
    exclude.length > 0 ? `slug_not_in: [${excludeString}]` : ``
  }`

  const whereClause = `${categoryClause} ${excludeClause}`

  const GET_ARTICLES = gql`
    query GetArticles($locale: String!, $limit: Int, $skip: Int) {
      articleCollection(
        where: {${whereClause}}
        locale: $locale
        limit: $limit
        skip: $skip
      ) {
        items {
          sys {
            id
          }
          title
          featured
          slug
          category {
            title
            slug
          }
          heroImage {
            title
            url(
              transform: {
                width: 800
                height: 600
                resizeStrategy: FILL
                resizeFocus: CENTER
                format: WEBP
                quality: 75
              }
            )
          }
          tags {
            title
            slug
            internal {
              type
            }
          }
        }
        total
        skip
        limit
      }
    }
  `

  const [isNewData, setIsNewData] = useState(false)
  const [articlesArray, setArticlesArray] = useState(articles)
  const [articlesQueryInfo, setArticlesQueryInfo] = useState({
    limit: 14,
    skip,
    more: true,
  })
  const [getArticles, { loading, data }] = useLazyQuery(GET_ARTICLES)
  const locale = getCurrentLang()

  useEffect(() => {
    if (data && isNewData) {
      setArticlesQueryInfo({
        ...articlesQueryInfo,
        skip: articlesQueryInfo.limit + articlesQueryInfo.skip,
        more:
          articlesQueryInfo.limit + data.articleCollection.skip <=
          data.articleCollection.total,
      })

      const newArticles = data.articleCollection.items.map(oldArticle => {
        return {
          id: oldArticle.sys.id,
          title: oldArticle.title,
          heroImage: {
            title: oldArticle.heroImage.title,
            fluid: {
              src: oldArticle.heroImage.url,
            },
          },
          slug: oldArticle.slug,
          category: oldArticle.category,
        }
      })

      setArticlesArray(articlesArray.concat(newArticles))
      setIsNewData(false)
    }
  }, [data])

  const getMoreArticles = (): void => {
    setIsNewData(true)
    getArticles({
      variables: {
        locale: locale,
        limit: articlesQueryInfo.limit,
        skip: articlesQueryInfo.skip,
      },
    })
  }

  const loadingText = useResourceTextData('community.loading', 'Loading...')
  const loadMoreText = useResourceTextData(
    'community.loadMore',
    'Load more articles'
  )

  return (
    <>
      <ArticlesGrid>
        {articlesArray.map(article => {
          return (
            <ArticleGridItem key={article.id} uniform={uniform}>
              <Card
                title={article.title}
                slug={`/community/${article.slug}/`}
                subTitle={{
                  title: article.category?.title,
                  slug: article.category?.slug
                    ? `/community/category/${article.category.slug}/`
                    : '',
                }}
                image={article.heroImage}
                tags={article.tags}
              />
            </ArticleGridItem>
          )
        })}
      </ArticlesGrid>
      {more && articlesQueryInfo.more && (
        <LoadMore onClick={getMoreArticles}>
          {loading ? loadingText : loadMoreText}
        </LoadMore>
      )}
    </>
  )
}

export default ArticlesList
