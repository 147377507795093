const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/app/.cache/dev-404-page.js"))),
  "component---src-pages-404-tsx": hot(preferDefault(require("/app/src/pages/404.tsx"))),
  "component---src-pages-careers-tsx": hot(preferDefault(require("/app/src/pages/careers.tsx"))),
  "component---src-pages-community-search-results-tsx": hot(preferDefault(require("/app/src/pages/community/search-results.tsx"))),
  "component---src-pages-find-tsx": hot(preferDefault(require("/app/src/pages/find.tsx"))),
  "component---src-pages-index-tsx": hot(preferDefault(require("/app/src/pages/index.tsx"))),
  "component---src-pages-style-guide-tsx": hot(preferDefault(require("/app/src/pages/style-guide.tsx"))),
  "component---src-pages-wholesale-index-tsx": hot(preferDefault(require("/app/src/pages/wholesale/index.tsx"))),
  "component---src-pages-wholesale-office-tsx": hot(preferDefault(require("/app/src/pages/wholesale/office.tsx"))),
  "component---src-templates-article-template-tsx": hot(preferDefault(require("/app/src/templates/articleTemplate.tsx"))),
  "component---src-templates-cafe-template-tsx": hot(preferDefault(require("/app/src/templates/cafeTemplate.tsx"))),
  "component---src-templates-community-category-template-tsx": hot(preferDefault(require("/app/src/templates/communityCategoryTemplate.tsx"))),
  "component---src-templates-community-tag-template-tsx": hot(preferDefault(require("/app/src/templates/communityTagTemplate.tsx"))),
  "component---src-templates-community-template-tsx": hot(preferDefault(require("/app/src/templates/communityTemplate.tsx"))),
  "component---src-templates-job-template-tsx": hot(preferDefault(require("/app/src/templates/jobTemplate.tsx"))),
  "component---src-templates-page-template-tsx": hot(preferDefault(require("/app/src/templates/pageTemplate.tsx"))),
  "component---src-templates-wholesale-team-template-tsx": hot(preferDefault(require("/app/src/templates/wholesaleTeamTemplate.tsx")))
}

