import classnames from 'classnames'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import Arrow from '../../images/svg/arrow-right.svg'
import Icon from '../atoms/icon'
import Link from './link'

type StyleButtonProp = {
  alternate?: boolean
}

const StyledButton = styled(Link)<StyleButtonProp>`
  ${({ theme }): CSSProp => css`
    cursor: pointer;
    border-radius: 4px;
    padding: 17px 34px;
    display: inline-block;
    background: ${(props: StyleButtonProp): CSSProp =>
      props.alternate ? 'none' : theme.colours.tertiary};
    border: ${(props: StyleButtonProp): CSSProp =>
      props.alternate ? `0.1rem solid ${theme.colours.lightTint2}` : 'none'};
    font-size: ${theme.font.fontSizeSmall};
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.2px;
    transition: background ${theme.animation.defaultLength} ease;
    color: ${(props: StyleButtonProp): CSSProp =>
      props.alternate ? theme.colours.primary : theme.colours.secondary};

    &.outline {
      background: none;
      border: solid ${theme.colours.tertiary} 1px;
      color: ${theme.colours.primary};
    }
    &:hover,
    &.active {
      background: ${theme.colours.lightTint1};
      color: ${theme.colours.secondary};
      border: ${(props: StyleButtonProp): CSSProp =>
        props.alternate ? `0.1rem solid ${theme.colours.lightTint1}` : 'none'};

      .icon {
        color: ${theme.colours.secondary};
      }
    }

    &.disabled,
    &:disabled,
    &[disabled] {
      opacity: 0.5;
      cursor: default;
      background: ${theme.colours.tertiary};
    }

    .icon {
      margin-right: 0.8rem;
      color: ${(props: StyleButtonProp): CSSProp =>
        props.alternate ? theme.colours.lightTint2 : 'inherit'};
    }
  `}
`
const ButtonIcon = (): JSX.Element => (
  <Icon className="icon" size="small">
    <Arrow />
  </Icon>
)

const Button = ({
  to,
  children,
  alternate,
  noIcon,
  ...props
}: {
  to?: string
  children: React.ReactNode
  active?: boolean
  disabled?: boolean
  alternate?: boolean
  noIcon?: boolean
}): JSX.Element => {
  const buttonClass = classnames('button', {
    active: props.active,
    disabled: props.disabled,
  })

  return to ? (
    <StyledButton
      alternate={alternate}
      className={buttonClass}
      to={to}
      {...props}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledButton
      alternate={alternate}
      className={buttonClass}
      as="button"
      {...props}
    >
      {!noIcon && <ButtonIcon />}
      {children}
    </StyledButton>
  )
}

export default Button
