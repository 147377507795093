import GoogleMapReact from 'google-map-react'
import { useEffect, useState } from 'react'
const { googleMapLoader } = GoogleMapReact

const GOOGLE_MAPS_API_THROTTLE = 200

const usePlacesApi = ({ api, placeId, delay = 0, ignoreOpeningHours = true }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    let ignore = ignoreOpeningHours
    let timer = null

    const getDetails = async () => {
      let placesApi = api

      if (!placesApi && !ignore) {
        const maps = await googleMapLoader({
          key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
          libraries: 'places',
        })
        placesApi = new maps.places.PlacesService(
          document.createElement('div')
        )
      }

      const fieldsToRequest = [
        'name',
        'utc_offset_minutes',
        'business_status',
        'url',
      ]

      if (!ignoreOpeningHours) {
        fieldsToRequest.push('opening_hours')
      }

      const request = {
        placeId: placeId?.trim(),
        fields: fieldsToRequest,
      }

      if (!ignore) {
        placesApi.getDetails(request, (place: any, status: string) => {
          if (status === 'OK') {
            if (place.opening_hours || ignoreOpeningHours) {
              if (!ignore) {
                setData({
                  url: place.url,
                  openStatus: ignoreOpeningHours ? 'unknown' : place.opening_hours.isOpen() ? 'open' : 'closed'
                })
              }
            } else {
              if (
                place.business_status &&
                place.business_status !== 'OPERATIONAL'
              ) {
                if (!ignore) {
                  setData({
                    url: place.url,
                    openStatus: place.business_status
                  })
                }
              } else {
                console.warn(
                  `No opening_hours or business_status from google: ${place.name}, please check place id`
                )
              }
            }
          } else {
            if (status === 'OVER_QUERY_LIMIT') {
              console.warn(`Try again - ${status} - ${placeId}`)
              clearTimeout(timer)
              timer = setTimeout(getDetails, GOOGLE_MAPS_API_THROTTLE)
            } else {
              console.warn(`Google places query failed: - ${status} - ${placeId}`)
            }
          }
        })
      }
    }

    timer = setTimeout(getDetails, delay * GOOGLE_MAPS_API_THROTTLE)

    // cancel actions on unmount
    return (): void => {
      ignore = true
      if (timer) {
        clearTimeout(timer)
      }
    }

  }, [api, placeId, ignoreOpeningHours, delay])
  return data
}

export default usePlacesApi
